import { useEffect } from "react"
import { getLoginUrl } from "../services/token-storage";

const loginUrl = getLoginUrl()

const LoginPage = ({location}:any) => {
    useEffect(() => {
        if(typeof window !== `undefined`) {
            if(location && location.state && location.state.prevPath) {
                window.localStorage.setItem('prevPath', location.state.prevPath);
            }
            window.location.replace(loginUrl)
        }
    },[])
    return null
}

export default LoginPage
